// import React from "react";
// import {
//   Box,
//   Container,
//   Grid,
//   Typography,
//   Card,
//   CardMedia,
//   CardContent,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import FundRaise from "./../../assets/images/donate/fundraise.jpg";
// import Banner from "../../constants/Banner/Banner";
// import Motivation3 from "./../../assets/images/MotivationalPrograms/Motivation3.jpeg";
// import PublicTalk4 from "./../../assets/images/PublicTalks/PublicTalk4.jpeg";
// import EducationalTour7 from "./../../assets/images/EducationTour/Tour7.jpeg";
// import ComputerClassImg1 from "./../../assets/images/ComputerClasswithLibrary/ComputerClass2.jpeg";
// import LibraryImg3 from "./../../assets/images/ComputerClasswithLibrary/Library7.jpg";
// import Counselling5 from "./../../assets/images/Education Counselling/Counselling6.jpeg";
// // Main Component
// const WorkshopAndEvents = () => {
//   const navigate = useNavigate();

//   const handleCardClick = () => {
//     navigate("/FilicitationCeremonyAlbum");
//   };

//   return (
//     <>
//       <Banner
//         Image={FundRaise}
//         BannerHeading={"Workshop & Events"}
//         IsHomeBanner={false}
//       />
//       <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
//         <Container>
//           <Grid container spacing={4} justifyContent="center">
//             {/* Card 1 */}
//             <Grid item xs={12} md={4}>
//               <Card
//                 sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
//                 onClick={handleCardClick}
//               >
//                 <CardMedia
//                   component="img"
//                   height="300"
//                   image="https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation_Guest1.jpg"
//                   alt="Guest"
//                 />
//                 <CardContent>
//                   <Typography variant="h5" fontWeight="bold" gutterBottom>
//                     TIET Filicitation Program 2024
//                   </Typography>
//                   <Typography variant="body1"></Typography>
//                 </CardContent>
//               </Card>
//             </Grid>

//             {/* Card 2 */}
//             <Grid item xs={12} md={4}>
//               <Card
//                 sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
//                 onClick={handleCardClick}
//               >
//                 <CardMedia
//                   component="img"
//                   height="300"
//                   image={Motivation3}
//                   alt="Event 2"
//                 />
//                 <CardContent>
//                   <Typography variant="h5" fontWeight="bold" gutterBottom>
//                     Motivational Programs
//                   </Typography>
//                   <Typography variant="body1"></Typography>
//                 </CardContent>
//               </Card>
//             </Grid>

//             <Grid item xs={12} md={4}>
//               <Card
//                 sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
//                 onClick={handleCardClick}
//               >
//                 <CardMedia
//                   component="img"
//                   height="300"
//                   image={Counselling5}
//                   alt="Event 3"
//                 />
//                 <CardContent>
//                   <Typography variant="h5" fontWeight="bold" gutterBottom>
//                     Educational Counselling
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Grid>

//             {/* Card 3 */}
//             <Grid item xs={12} md={4}>
//               <Card
//                 sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
//                 onClick={handleCardClick}
//               >
//                 <CardMedia
//                   component="img"
//                   height="300"
//                   image={PublicTalk4}
//                   alt="Event 3"
//                 />
//                 <CardContent>
//                   <Typography variant="h5" fontWeight="bold" gutterBottom>
//                     Public Talks
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Grid>

//             {/* Card 4 */}
//             <Grid item xs={12} md={4}>
//               <Card
//                 sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
//                 onClick={handleCardClick}
//               >
//                 <CardMedia
//                   component="img"
//                   height="300"
//                   image={EducationalTour7}
//                   alt="Event 4"
//                 />
//                 <CardContent>
//                   <Typography variant="h5" fontWeight="bold" gutterBottom>
//                     Educational Tours
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Grid>

//             {/* Card 5 */}
//             <Grid item xs={12} md={4}>
//               <Card
//                 sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
//                 onClick={handleCardClick}
//               >
//                 <CardMedia
//                   component="img"
//                   height="300"
//                   image={ComputerClassImg1}
//                   alt="Event 5"
//                 />
//                 <CardContent>
//                   <Typography variant="h5" fontWeight="bold" gutterBottom>
//                     Computer Classes
//                   </Typography>
//                 </CardContent>
//               </Card>
//             </Grid>

//             {/* Card 6 */}
//             <Grid item xs={12} md={4}>
//               <Card
//                 sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
//                 onClick={handleCardClick}
//               >
//                 <CardMedia
//                   component="img"
//                   height="300"
//                   image={LibraryImg3}
//                   alt="Event 6"
//                 />
//                 <CardContent>
//                   <Typography variant="h5" fontWeight="bold" gutterBottom>
//                     TIET Library
//                   </Typography>
//                   <Typography variant="body1"></Typography>
//                 </CardContent>
//               </Card>
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//     </>
//   );
// };

// export default WorkshopAndEvents;

import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FundRaise from "./../../assets/images/donate/fundraise.jpg";
import Banner from "../../constants/Banner/Banner";
import Motivation3 from "./../../assets/images/MotivationalPrograms/Motivation3.jpeg";
import PublicTalk4 from "./../../assets/images/PublicTalks/PublicTalk4.jpeg";
import EducationalTour7 from "./../../assets/images/EducationTour/Tour7.jpeg";
import ComputerClassImg1 from "./../../assets/images/ComputerClasswithLibrary/ComputerClass2.jpeg";
import LibraryImg3 from "./../../assets/images/ComputerClasswithLibrary/Library7.jpg";
import Counselling5 from "./../../assets/images/Education Counselling/Counselling6.jpeg";

// Main Component
const WorkshopAndEvents = () => {
  const navigate = useNavigate();

  // Updated handleCardClick function with different paths for each card
  const handleCardClick = (path) => {
    navigate(`/${path}`);
  };

  return (
    <>
      <Banner
        Image={FundRaise}
        BannerHeading={"Workshop & Events"}
        IsHomeBanner={false}
      />
      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Grid container spacing={4} justifyContent="center">
            {/* Filicitation Program Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
                onClick={() => handleCardClick("FilicitationCeremonyAlbum")}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image="https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation_Guest1.jpg"
                  alt="Guest"
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    TIET Filicitation Program 2024
                  </Typography>
                  <Typography variant="body1"></Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Motivational Programs Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
                onClick={() => handleCardClick("MotivationalProgramsAlbum")}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={Motivation3}
                  alt="Motivational Programs"
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Motivational Programs
                  </Typography>
                  <Typography variant="body1"></Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Educational Counselling Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
                onClick={() => handleCardClick("EducationCounsellingAlbum")}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={Counselling5}
                  alt="Educational Counselling"
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Educational Counselling
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Public Talks Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
                onClick={() => handleCardClick("PublicTalkAlbum")}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={PublicTalk4}
                  alt="Public Talks"
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Public Talks
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Educational Tours Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
                onClick={() => handleCardClick("TourAlbum")}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={EducationalTour7}
                  alt="Educational Tours"
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Educational Tours
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Computer Classes Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
                onClick={() => handleCardClick("ComputerClassAlbum")}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={ComputerClassImg1}
                  alt="Computer Classes"
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    Computer Classes
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Library Card */}
            <Grid item xs={12} md={4}>
              <Card
                sx={{ borderRadius: 3, boxShadow: 3, cursor: "pointer" }}
                onClick={() => handleCardClick("LibraryAlbum")}
              >
                <CardMedia
                  component="img"
                  height="300"
                  image={LibraryImg3}
                  alt="Library"
                />
                <CardContent>
                  <Typography variant="h5" fontWeight="bold" gutterBottom>
                    TIET Library
                  </Typography>
                  <Typography variant="body1"></Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default WorkshopAndEvents;

// import { Route, Routes, useLocation } from "react-router-dom";
// import React, { useEffect } from "react";
// import "./App.css";
// import "./App.scss";
// import ReactGA from "react-ga";
// import OurTeam from "./containers/AboutUs/OurTeam";
// import Home from "./containers/Home/Home";
// import AppHeader from "./layouts/AppHeader/AppHeader";
// import AppFooter from "./layouts/AppFooter/AppFooter";
// import Education from "./containers/OurWorks/Education";
// import WomenEmpowerment from "./containers/OurWorks/WomenEmpowerment";
// import NotFound404 from "./containers/404/NotFound404";
// import NayaSavera from "./containers/campaign/nayasavera";
// import IndividualSupport from "./containers/GetInvolved/IndividualSupport";
// import CorporatePartnerships from "./containers/GetInvolved/CorporatePartnership";
// import SchoolPartnerShips from "./containers/GetInvolved/SchoolPartnerships";
// import DonateUs from "./containers/DonateUs/DonateUs";
// import TietBlogs from "./containers/ResourceCenter/TietBlog";
// import ContactUs from "./containers/ContactUs/ContactUs";
// import { Toolbar } from "@mui/material";
// import WorkshopAndEvents from "./containers/MediaCenter/WorkshopAndEvents";
// import WorkshopAndEventsDetails from "./containers/MediaCenter/WorkshopAndEventsDetails";
// import ApplicationForm from "./containers/ResourceCenter/ApplicationForm";
// import Login from "./containers/Login/Login";
// import SignUp from "./containers/SignUp/SignUp";
// import AdminDashboard from "./containers/AdminDashboard/AdminDashboard";
// import DashboardManagement from "./containers/DashboardManagement/DashboardManagement";
// import StudentDetail from "./containers/StudentDetail/StudnetDetail";
// import ComputerCourse from "./containers/ComputerCourse/ComputerCourse";
// import EnglishCourse from "./containers/EnglishCourse/EnglishCourse";
// import CarrersPage from "./containers/ResourceCenter/Carrers";
// import SunburstChart from "./containers/ResourceCenter/sunburstchart";
// import CarrerTree from "./containers/ResourceCenter/treechart";
// import CongratsAlbum from "./containers/MediaCenter/Congrats_Album";
// import Achivements from "./containers/MediaCenter/Achivements";
// import SchoolAlbum from "./containers/GetInvolved/MohammadiaSchoolAlbum";
// import SocialMediaWidget from "./layouts/SocialMediaComponent/SocialMediaWidget";
// import ScrollToTop from "./layouts/AppHeader/ScrollToTop";
// import AlfalahSchoolAlbum from "./containers/GetInvolved/AlfalahSchoolAlbum";
// import InternshipAlbum from "./containers/MediaAlbums/InternshipAlbum";
// import LibraryAlbum from "./containers/MediaAlbums/LibraryAlbum";
// import PublicTalkAlbum from "./containers/MediaAlbums/PublicTalkAlbum";
// import FilicitationCeremonyAlbum from "./containers/MediaAlbums/FilicitationCeremonyAlbum";
// import TourAlbum from "./containers/MediaAlbums/TourAlbum";
// import ComputerClassAlbum from "./containers/MediaAlbums/ComputerClassAlbum";
// import EducationCounsellingAlbum from "./containers/MediaAlbums/EducationCounsellingAlbum";
// import MotivationalProgramsAlbum from "./containers/MediaAlbums/MotivationalProgramAlbum";
// import ReactGA from "react-ga4";

// function App() {
//   return (
//     <>
//       <AppHeader />
//       <ScrollToTop />
//       <Toolbar />
//       <div className="mainContent">
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="aboutUs" element={<OurTeam />} />
//           <Route path="education" element={<Education />} />
//           <Route path="womenEmpowerment" element={<WomenEmpowerment />} />
//           <Route path="individualSupport" element={<IndividualSupport />} />
//           <Route
//             path="corporatePartnerships"
//             element={<CorporatePartnerships />}
//           />
//           <Route path="SchoolPartnerships" element={<SchoolPartnerShips />} />
//           <Route path="WorkshopAndEvents" element={<WorkshopAndEvents />} />
//           <Route
//             path="WorkshopAndEventsDetails"
//             element={<WorkshopAndEventsDetails />}
//           />
//           <Route path="nayaSavera" element={<NayaSavera />} />
//           <Route path="DonateUs" element={<DonateUs />} />
//           <Route path="TietBlogs" element={<TietBlogs />} />
//           <Route path="ContactUs" element={<ContactUs />} />
//           <Route path="*" element={<NotFound404 />} />
//           <Route path="ApplicationForm" element={<ApplicationForm />} />
//           <Route path="login" element={<Login />} />
//           <Route path="signup" element={<SignUp />} />
//           <Route path="admindashboard" element={<AdminDashboard />} />
//           <Route path="dashboardmanagement" element={<DashboardManagement />} />
//           <Route path="studentdetail" element={<StudentDetail />} />
//           <Route path="computercourse" element={<ComputerCourse />} />
//           <Route path="englishcourse" element={<EnglishCourse />} />
//           <Route path="Carrers" element={<CarrersPage />} />
//           <Route path="Sunburstchart" element={<SunburstChart />} />
//           <Route path="Carrertree" element={<CarrerTree />} />
//           <Route path="Congrats" element={<CongratsAlbum />} />
//           <Route path="Achivements" element={<Achivements />} />
//           <Route path="SchoolAlbum" element={<SchoolAlbum />} />
//           <Route path="AlfalahSchoolAlbum" element={<AlfalahSchoolAlbum />} />
//           <Route path="InternshipAlbum" from element={<InternshipAlbum />} />
//           <Route path="LibraryAlbum" from element={<LibraryAlbum />} />
//           <Route path="PublicTalkAlbum" from element={<PublicTalkAlbum />} />
//           <Route
//             path="EducationCounsellingAlbum"
//             from
//             element={<EducationCounsellingAlbum />}
//           />
//           <Route
//             path="MotivationalProgramsAlbum"
//             from
//             element={<MotivationalProgramsAlbum />}
//           />
//           <Route
//             path="ComputerClassAlbum"
//             from
//             element={<ComputerClassAlbum />}
//           />
//           <Route path="TourAlbum" from element={<TourAlbum />} />
//           <Route
//             path="FilicitationCeremonyAlbum"
//             from
//             element={<FilicitationCeremonyAlbum />}
//           />
//         </Routes>
//         <SocialMediaWidget />
//       </div>
//       <AppFooter />
//     </>
//   );
// }

// export default App;

import { Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import "./App.css";
import "./App.scss";
import ReactGA from "react-ga4"; // Corrected import

import OurTeam from "./containers/AboutUs/OurTeam";
import Home from "./containers/Home/Home";
import AppHeader from "./layouts/AppHeader/AppHeader";
import AppFooter from "./layouts/AppFooter/AppFooter";
import Education from "./containers/OurWorks/Education";
import WomenEmpowerment from "./containers/OurWorks/WomenEmpowerment";
import NotFound404 from "./containers/404/NotFound404";
import NayaSavera from "./containers/campaign/nayasavera";
import IndividualSupport from "./containers/GetInvolved/IndividualSupport";
import CorporatePartnerships from "./containers/GetInvolved/CorporatePartnership";
import SchoolPartnerShips from "./containers/GetInvolved/SchoolPartnerships";
import DonateUs from "./containers/DonateUs/DonateUs";
import TietBlogs from "./containers/ResourceCenter/TietBlog";
import ContactUs from "./containers/ContactUs/ContactUs";
import { Toolbar } from "@mui/material";
import WorkshopAndEvents from "./containers/MediaCenter/WorkshopAndEvents";
import WorkshopAndEventsDetails from "./containers/MediaCenter/WorkshopAndEventsDetails";
import ApplicationForm from "./containers/ResourceCenter/ApplicationForm";
import Login from "./containers/Login/Login";
import SignUp from "./containers/SignUp/SignUp";
import AdminDashboard from "./containers/AdminDashboard/AdminDashboard";
import DashboardManagement from "./containers/DashboardManagement/DashboardManagement";
import StudentDetail from "./containers/StudentDetail/StudnetDetail";
import ComputerCourse from "./containers/ComputerCourse/ComputerCourse";
import EnglishCourse from "./containers/EnglishCourse/EnglishCourse";
import CarrersPage from "./containers/ResourceCenter/Carrers";
import SunburstChart from "./containers/ResourceCenter/sunburstchart";
import CarrerTree from "./containers/ResourceCenter/treechart";
import CongratsAlbum from "./containers/MediaCenter/Congrats_Album";
import Achivements from "./containers/MediaCenter/Achivements";
import SchoolAlbum from "./containers/GetInvolved/MohammadiaSchoolAlbum";
import SocialMediaWidget from "./layouts/SocialMediaComponent/SocialMediaWidget";
import ScrollToTop from "./layouts/AppHeader/ScrollToTop";
import AlfalahSchoolAlbum from "./containers/GetInvolved/AlfalahSchoolAlbum";
import InternshipAlbum from "./containers/MediaAlbums/InternshipAlbum";
import LibraryAlbum from "./containers/MediaAlbums/LibraryAlbum";
import PublicTalkAlbum from "./containers/MediaAlbums/PublicTalkAlbum";
import FilicitationCeremonyAlbum from "./containers/MediaAlbums/FilicitationCeremonyAlbum";
import TourAlbum from "./containers/MediaAlbums/TourAlbum";
import ComputerClassAlbum from "./containers/MediaAlbums/ComputerClassAlbum";
import EducationCounsellingAlbum from "./containers/MediaAlbums/EducationCounsellingAlbum";
import MotivationalProgramsAlbum from "./containers/MediaAlbums/MotivationalProgramAlbum";

const MEASUREMENT_ID = "G-WMFLZYJJ4M"; // Your GA4 Measurement ID

function App() {
  const location = useLocation();

  // Initialize GA and track page views
  useEffect(() => {
    ReactGA.initialize(MEASUREMENT_ID);
    ReactGA.send("pageview"); // Initial page load tracking
  }, []);

  // Track page views on route change
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <>
      <AppHeader />
      <ScrollToTop />
      <Toolbar />
      <div className="mainContent">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="aboutUs" element={<OurTeam />} />
          <Route path="education" element={<Education />} />
          <Route path="womenEmpowerment" element={<WomenEmpowerment />} />
          <Route path="individualSupport" element={<IndividualSupport />} />
          <Route
            path="corporatePartnerships"
            element={<CorporatePartnerships />}
          />
          <Route path="SchoolPartnerships" element={<SchoolPartnerShips />} />
          <Route path="WorkshopAndEvents" element={<WorkshopAndEvents />} />
          <Route
            path="WorkshopAndEventsDetails"
            element={<WorkshopAndEventsDetails />}
          />
          <Route path="nayaSavera" element={<NayaSavera />} />
          <Route path="DonateUs" element={<DonateUs />} />
          <Route path="TietBlogs" element={<TietBlogs />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="*" element={<NotFound404 />} />
          <Route path="ApplicationForm" element={<ApplicationForm />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="admindashboard" element={<AdminDashboard />} />
          <Route path="dashboardmanagement" element={<DashboardManagement />} />
          <Route path="studentdetail" element={<StudentDetail />} />
          <Route path="computercourse" element={<ComputerCourse />} />
          <Route path="englishcourse" element={<EnglishCourse />} />
          <Route path="Carrers" element={<CarrersPage />} />
          <Route path="Sunburstchart" element={<SunburstChart />} />
          <Route path="Carrertree" element={<CarrerTree />} />
          <Route path="Congrats" element={<CongratsAlbum />} />
          <Route path="Achivements" element={<Achivements />} />
          <Route path="SchoolAlbum" element={<SchoolAlbum />} />
          <Route path="AlfalahSchoolAlbum" element={<AlfalahSchoolAlbum />} />
          <Route path="InternshipAlbum" element={<InternshipAlbum />} />
          <Route path="LibraryAlbum" element={<LibraryAlbum />} />
          <Route path="PublicTalkAlbum" element={<PublicTalkAlbum />} />
          <Route
            path="EducationCounsellingAlbum"
            element={<EducationCounsellingAlbum />}
          />
          <Route
            path="MotivationalProgramsAlbum"
            element={<MotivationalProgramsAlbum />}
          />
          <Route path="ComputerClassAlbum" element={<ComputerClassAlbum />} />
          <Route path="TourAlbum" element={<TourAlbum />} />
          <Route
            path="FilicitationCeremonyAlbum"
            element={<FilicitationCeremonyAlbum />}
          />
        </Routes>
        <SocialMediaWidget />
      </div>
      <AppFooter />
    </>
  );
}

export default App;
